import React, { FC, Fragment, useRef } from "react";
import Buttons from "../Buttons/Buttons";
import FormInput from "../FormInput/FormInput";
import { useTypeSelector } from "../../hooks/useTypedSelector";
import apiConfig from "../../api/apiConfig";
import icons from "../../assets/icons/icons";
import "./styles.scss";
import { fieldToArray, formatDateIntlDate } from "../UI/functions/functions";

interface iArrayFile {
  url: string;
  original_name: string;
}

interface FormReportProps {
  userResponsible: any; // тип данных для userResponsible
  dataPress: any; // тип данных для userResponsible
  dataOption?: any; // тип данных для userResponsible
  disabled: boolean; // тип данных для userResponsible
  handleUploadFiles: (files: FileList | null, text: string) => void; // тип функции для handleUploadFiles
  handleChange: (key: string, text: string) => void; // тип функции для handleChange
  handleOpenModal: () => void; // тип функции для handleOpenModal
  handleStageStatus: (e: boolean) => void; // тип функции для handleOpenModal
  handleMarks?: (key: string, e: string | boolean) => void; // тип функции для handleOpenModal
}

const FormReport: FC<FormReportProps> = ({
  userResponsible,
  handleUploadFiles,
  handleChange,
  handleOpenModal,
  handleStageStatus,
  handleMarks,
  dataPress,
  dataOption,
  disabled,
}) => {
  const userData = JSON.parse(localStorage.getItem("account") || "{}");
  const imageInputRef = useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageUpload = () => {
    if (imageInputRef.current) {
      imageInputRef.current.click(); // Открыть пикер картинок при вызове этой функции
    }
  };

  const handleFileUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Открыть пикер файлов при вызове этой функции
    }
  };

  return (
    <Fragment>
      <div className={`formReport`}>
        <div className={`containerReportForm  ${disabled && "disabled"}`}>
          <h5 className="constructionInfoSubTitle">
            {!disabled && userResponsible
              ? "Добавить фотографии"
              : "Фотографии"}
          </h5>
          {dataPress?.images &&
            dataPress?.images.length > 0 &&
            dataPress?.images.map((item: iArrayFile) => {
              return (
                <a
                  href={`${apiConfig.baseUrlMedia}${item.url}`}
                  target="_blank"
                  download={`${apiConfig.baseUrlMedia}${item.url}`}
                  className="fileContainer"
                >
                  <img src={icons.file}></img>
                  <p>{item.original_name}</p>
                </a>
              );
            })}
          {/* Пикер для картинок */}
          <input
            type="file"
            style={{ display: "none" }}
            ref={imageInputRef}
            accept="image/*"
            multiple
            onChange={(e) => {
              handleUploadFiles(e.target.files, "image");
            }}
          />
          {!disabled && userResponsible && (
            <Buttons
              text={"Загрузить"}
              className="uploadWhiteButton"
              onClick={handleImageUpload}
            />
          )}
        </div>
        <div className={`containerReportForm  ${disabled && "disabled"}`}>
          <h5 className="constructionInfoSubTitle">
            {!disabled && userResponsible ? "Добавить документы" : "Документы"}
          </h5>
          {dataPress?.documents &&
            dataPress.documents.length > 0 &&
            dataPress.documents.map((item: iArrayFile) => {
              return (
                <a
                  href={`${apiConfig.baseUrlMedia}${item.url}`}
                  target="_blank"
                  download={`${apiConfig.baseUrlMedia}${item.url}`}
                  className="fileContainer"
                >
                  <img src={icons.file}></img>
                  <p>{item.original_name}</p>
                </a>
              );
            })}
          <input
            type="file"
            style={{ display: "none" }}
            ref={fileInputRef}
            multiple
            accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt"
            onChange={(e) => {
              handleUploadFiles(e.target.files, "file");
            }}
          />
          {!disabled && userResponsible && (
            <Buttons
              text={"Загрузить"}
              className="uploadWhiteButton"
              onClick={handleFileUpload}
            />
          )}
        </div>
        <div className={`containerReportForm  ${disabled && "disabled"}`}>
          <h5 className="constructionInfoSubTitle">
            {!disabled && userResponsible ? "Добавить пояснение" : "Пояснение"}
          </h5>
          {!disabled && userResponsible ? (
            <FormInput
              style={""}
              value={dataPress.remark}
              onChange={(e) => handleChange("remark", e)}
              subInput={undefined}
              required={false}
              error={""}
              keyData={""}
              textArea
              copy={false}
            />
          ) : (
            <p>{dataPress.remark}</p>
          )}
        </div>
        {!disabled &&
          (userResponsible ? (
            <Buttons
              text={"Отправить на проверку"}
              onClick={() => handleOpenModal()}
              className="saveButtonReport"
            />
          ) : (
            dataPress.is_ready && (
              <div className="containerButton">
                <Buttons
                  text={"Отклонить"}
                  onClick={() => handleOpenModal()}
                  className="saveButtonClose"
                />
                <Buttons
                  text={"Принять"}
                  className="saveButtonComplete"
                  onClick={() => {
                    handleStageStatus(true);
                  }}
                />
              </div>
            )
          ))}
        {dataPress.comment && (
          <div className="errorComment">
            <h3 className="titleErrorComment">
              {` Отклонен ${
                dataPress.created_at && formatDateIntlDate(dataPress.created_at)
              }`}
            </h3>
            <p className="textErrorComment">{dataPress.comment}</p>
          </div>
        )}
        {fieldToArray(dataOption?.LIST?.stages?.child?.children).length > 0 &&
          (userData.roles === "EMPLOYEE" || userData.roles === "ADMIN") && (
            <div className="containerMark">
              <h1>Метки</h1>
              {fieldToArray(dataOption?.LIST?.stages?.child?.children).map(
                (item: any) => {
                  console.log("dataPress", dataPress);

                  if (
                    item.key === "constraction_install" ||
                    item.key === "mark_document" ||
                    item.key === "mark_photos"
                  )
                    return (
                      <FormInput
                        subInput={item?.value?.label}
                        required={item?.value?.required}
                        options={item?.value?.choices}
                        type={item?.value?.type}
                        style=""
                        value={dataPress[item.key]}
                        checked={dataPress[item.key]}
                        onChange={(e) => {
                          if (
                            item.key === "mark_document" ||
                            item.key === "mark_photos"
                          ) {
                            return;
                          }
                          //@ts-ignore
                          handleMarks(item.key, e);
                        }}
                        onCheck={(isChecked) => {
                          if (
                            item.key === "mark_document" ||
                            item.key === "mark_photos"
                          ) {
                            //@ts-ignore
                            handleMarks(item.key, isChecked);
                          }
                        }}
                        error={""}
                        keyData={""}
                        friedlyInput={true}
                      />
                    );
                }
              )}
            </div>
          )}
      </div>
    </Fragment>
  );
};

export default FormReport;
