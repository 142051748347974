import React, { FC, Fragment, useEffect, useState } from "react";
import "./styles.scss";
import icons from "../../assets/icons/icons";
import Buttons from "../Buttons/Buttons";
import { useDispatch } from "react-redux";
import { AuthActionCreators } from "../../store/reducers/auth/action-creator";
import { RouteNames } from "../../routes";
import { useNavigate } from "react-router-dom";
import Modal from "../Modal/Modal";
import { fieldToArray, formatName } from "../UI/functions/functions";
import FormInput from "../FormInput/FormInput";
import UserApiRequest from "../../api/User/Users";
import { useTypeSelector } from "../../hooks/useTypedSelector";
import { DataPressActionCreators } from "../../store/reducers/dataPressItem/action-creator";
import ErrorMessage from "../UI/ErrorMassage/ErrorMassage";
import ConstructionApiRequest from "../../api/Construction/Construction";
import { IPaginationData } from "../../models/IPagination";

interface IHeader {
  handleDataConstruction?: (data: any) => void;
}

const Header: FC<IHeader> = ({ handleDataConstruction }) => {
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const userApi = new UserApiRequest();
  const constructionApi = new ConstructionApiRequest();

  const [dataOption, setDataOption] = useState<any>();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isError, setIsError] = useState({ text: "", type: "error" });
  const [dataConstruction, setDataConsruction] = useState<IPaginationData>();

  const userData = JSON.parse(localStorage.getItem("account") || "{}");

  const currentUrl = window.location.href;
  const urlObject = new URL(currentUrl);
  const path = urlObject.pathname;

  console.log("urlParams", path);

  const dataPress = useTypeSelector(
    (state: any) => state.dataPressReducer.dataPress
  );

  const buttonsData = userData.roles.flatMap((item: any) => {
    if (item === "EMPLOYEE") {
      return [
        {
          id: 1,
          ico: icons.addFile,
          style: "objectButton",
          text: "Объект",
          onClick: () => {
            navigation(RouteNames.CREATEOBJECT);
          },
        },
      ];
    } else if (item === "ADMIN") {
      return [
        {
          id: 1,
          ico: icons.addFile,
          style: "objectButton",
          text: "Объект",
          onClick: () => {
            navigation(RouteNames.CREATEOBJECT);
          },
        },
        {
          id: 2,
          text: "Сотрудники",
          style: "modalButton",
          onClick: () => {
            handleModalCreate();
          },
        },
      ];
    } else {
      return []; // Если роль не подходит, возвращаем пустой массив
    }
  });

  const handleChange = (fieldName: string, fieldValue: string | boolean) => {
    dispatch(DataPressActionCreators.setDataPress(fieldName, fieldValue));
  };

  const handleModalCreate = () => {
    userApi.options().then((resp) => {
      if (resp.success) {
        //@ts-ignore
        setDataOption(resp.data && resp?.data?.actions?.CREATE);
        setIsOpenModal(true);
      }
    });
  };
  const handleUserCreate = () => {
    userApi.create({ body: dataPress }).then((resp) => {
      if (resp.success) {
        //@ts-ignore

        setIsOpenModal(false);
      } else {
        setIsError({ text: "Произошла ошибка", type: "error" });
      }
    });
  };

  const logOut = () => {
    dispatch(
      //@ts-ignore
      AuthActionCreators.logout()
    );
    navigation(RouteNames.LOGIN);
  };

  const handleSearch = (search: string) => {
    constructionApi.list({ urlParams: `?search=${search}` }).then((resp) => {
      if (resp.success) {
        //@ts-ignore
        handleDataConstruction(resp.data as IPaginationData);
      }
    });
  };

  return (
    <Fragment>
      {isError.text != "" && (
        <ErrorMessage
          type={isError.type}
          message={isError.text}
          onClose={() => {
            setIsError({ text: "", type: "error" });
          }}
        />
      )}
      <Modal
        classModal="modalCreate"
        content={
          <div className="modalCreateUser">
            <div className="headerModal">
              <h1 className="titleModal">Добавить сотрудника</h1>
              <h3 className="subTitleModal">
                Приглашенный сотрудник сможет войти по указанному номеру
                телефона
              </h3>
            </div>

            {fieldToArray(dataOption).map((item) => {
              return (
                <FormInput
                  style={""}
                  value={undefined}
                  onChange={(value) => {
                    handleChange(item.key, value);
                  }}
                  subInput={item.value.label}
                  required={item.value.required}
                  error={""}
                  type={item.value.type}
                  keyData={item.key}
                  friedlyInput={true}
                />
              );
            })}

            <div className="modalButtonContainer">
              <Buttons
                text={"Отмена"}
                onClick={() => setIsOpenModal(false)}
                className="whiteButton"
              />
              <Buttons text={"Отправить"} onClick={handleUserCreate} />
            </div>
          </div>
        }
        isOpen={isOpenModal}
        onClose={() => {
          setIsOpenModal(false);
        }}
      />
      <div className="headerContainer">
        <div
          className="containerLogo"
          onClick={() => {
            navigation(RouteNames.HOMEPAGE);
          }}
        >
          <img src={icons.Logo} className="bikLogoLogin"></img>
          <h1>Контроль строительства</h1>
        </div>
        <div className="buttonsContainerName">
          <div className="containerLogoMobile">
            <div
              className="containerLogo"
              onClick={() => {
                navigation(RouteNames.HOMEPAGE);
              }}
            >
              <img src={icons.Logo} className="bikLogoLogin"></img>
              <h1>Контроль строительства</h1>
            </div>
            <div className="userContainer">
              <h4>{formatName(userData?.fio)}</h4>
              <Buttons
                text={"Выйти"}
                ico={icons.logOut}
                className="logoutButton"
                onClick={() => {
                  logOut();
                }}
              />
            </div>
          </div>
          {userData?.roles?.flatMap((item: any) => {
            console.log("item", item);

            if (item === "EMPLOYEE" || item === "ADMIN") {
              return (
                path === "/" && (
                  <FormInput
                    style={"searchInput"}
                    rightIco={icons.search}
                    value={undefined}
                    onChange={(value) => handleSearch(value)}
                    subInput={undefined}
                    required={false}
                    placeholder="Найти..."
                    error={""}
                    keyData={""}
                  />
                )
              );
            }
          })}
          <div className="buttonsContainer">
            {buttonsData.map((item: any) => {
              return (
                <Buttons
                  text={item.text}
                  ico={item.ico}
                  key={item.id}
                  className={item.style}
                  onClick={item.onClick}
                />
              );
            })}
          </div>
          <div className="userContainer">
            <h4>{formatName(userData?.fio)}</h4>
            <Buttons
              text={"Выйти"}
              ico={icons.logOut}
              className="logoutButton"
              onClick={() => {
                logOut();
              }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Header;
