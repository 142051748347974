import React, { FC, Fragment, useState } from "react";
import { IOptionInput } from "../../models/IOptionInput";
import { fieldToArray } from "../UI/functions/functions";
import FormInput from "../FormInput/FormInput";
import "./styles.scss";
import Buttons from "../Buttons/Buttons";
import icons from "../../assets/icons/icons";
import FormBlock from "../FormBlock/FormBlock";
import { useDispatch } from "react-redux";
import { DataPressActionCreators } from "../../store/reducers/dataPressItem/action-creator";
import { useTypeSelector } from "../../hooks/useTypedSelector";
import ConstructionApiRequest from "../../api/Construction/Construction";
import { useNavigate } from "react-router-dom";
import { RouteNames } from "../../routes";
import ErrorMessage from "../UI/ErrorMassage/ErrorMassage";

interface IList {
  address: IOptionInput;
  contractor: IOptionInput;
  created_at: IOptionInput;
  id: IOptionInput;
  name: IOptionInput;
  responsible: IOptionInput;
  stages: IOptionInput;
}

interface IData {
  CREATE: IList;
}

export interface ICreateObject {
  dataOption: IData;
}

const CreateObjectComponent: FC<ICreateObject> = ({ dataOption }) => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [isError, setIsError] = useState({ text: "", type: "error" });

  const constructionApi = new ConstructionApiRequest();

  const dataPress = useTypeSelector(
    (state: any) => state.dataPressReducer.dataPress
  );

  console.log("dataPress", dataPress);

  const handleChange = (fieldName: string, fieldValue: string | boolean) => {
    if (["phone", "fio", "work_position", "email"].includes(fieldName)) {
      dispatch(
        DataPressActionCreators.setDataPress("responsible", {
          ...dataPress.responsible,
          [fieldName]: fieldValue,
        })
      );
    } else {
      dispatch(DataPressActionCreators.setDataPress(fieldName, fieldValue));
    }
  };

  const createConstruction = () => {
    constructionApi.create({ body: dataPress }).then((resp) => {
      if (resp.success) {
        navigation(RouteNames.HOMEPAGE);
      } else {
        setIsError({ text: "Произошла ошибка", type: "error" });
      }
    });
  };

  console.log("dataPress", dataPress);

  return (
    <Fragment>
      {isError.text != "" && (
        <ErrorMessage
          type={isError.type}
          message={isError.text}
          onClose={() => {
            setIsError({ text: "", type: "error" });
          }}
        />
      )}
      <div className="formContainer">
        <h1 className="titleForm">Создание объекта</h1>

        {fieldToArray(dataOption?.CREATE).map((item) => {
          if (["id", "stages", "responsible"].includes(item.key)) return null;

          return (
            <FormInput
              style={""}
              value={
                Object.entries(dataPress).length > 0 ? dataPress[item.key] : ""
              }
              onChange={(e) => {
                handleChange(item.key, e);
              }}
              subInput={item.value.label}
              required={item.value.required}
              error={""}
              keyData={item.key}
              type={item.value.type}
            />
          );
        })}

        {
          <div className="responsibleContainer">
            <h1>Ответственный подрядчика</h1>
            {
              //@ts-ignore
              fieldToArray(dataOption?.CREATE?.responsible?.children).map(
                (child) => (
                  <FormInput
                    style={""}
                    value={""}
                    onChange={(e) => {
                      handleChange(child.key, e);
                    }}
                    subInput={child.value.label}
                    required={child.value.required}
                    error={""}
                    keyData={child.key}
                    friedlyInput
                    type={child.value.type}
                    helpText={
                      child.key === "phone"
                        ? "Используется для входа в систему и сдачи отчетности"
                        : ""
                    }
                  />
                )
              )
            }
          </div>
        }

        <h1 className="titleForm__line">Этапы</h1>

        {
          <FormBlock
            title={`Этап`}
            dataOptions={
              //@ts-ignore
              fieldToArray(dataOption?.CREATE?.stages?.child?.children)
            }
            onChange={(e: any) => {
              handleChange("stages", e);
            }}
            initData={dataPress?.stages}
            textAddButton={"Добавить этап"}
            onClick={undefined}
            dataPlatform={undefined}
          />
        }

        <h1 className="titleEnd">Завершить создание объекта</h1>
        <Buttons
          text={"Сохранить"}
          ico={icons.save}
          className="saveButton"
          onClick={() => createConstruction()}
        />
      </div>
    </Fragment>
  );
};

export default CreateObjectComponent;
