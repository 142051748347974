import React, { FC, Fragment, useEffect, useState } from "react";
import "./styles.scss";
import Buttons from "../Buttons/Buttons";
import { ISendLogin } from "../../models/ISendLogin";
import { fieldToArray } from "../UI/functions/functions";
import FormInput from "../FormInput/FormInput";
import UserApiRequest from "../../api/User/Users";
import { AuthActionCreators } from "../../store/reducers/auth/action-creator";
import { useTypeSelector } from "../../hooks/useTypedSelector";
import { useNavigate } from "react-router-dom";
import { RouteNames } from "../../routes";
import { useDispatch } from "react-redux";
import { DataPressActionCreators } from "../../store/reducers/dataPressItem/action-creator";
import icons from "../../assets/icons/icons";

interface iLoginForm {
  title: string;
  optionForm: ISendLogin | undefined;
}

const LoginForm: FC<iLoginForm> = ({ title, optionForm }) => {
  const userApi = new UserApiRequest();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const dataPress = useTypeSelector(
    (state: any) => state.dataPressReducer.dataPress
  );

  const handleChange = (fieldName: string, fieldValue: string | boolean) => {
    dispatch(DataPressActionCreators.setDataPress(fieldName, fieldValue));
  };

  const [isCode, setIsCode] = useState<boolean>(false);
  const [isTimer, setIsTimer] = useState<number>(0);

  const { isAuth, error, isLoading } = useTypeSelector(
    (state) => state.authReducer
  );

  const getCode = () => {
    setIsTimer(60);
    userApi.authCode({ phone: dataPress.phone }).then((resp) => {
      if (resp.success) {
        setIsCode(resp.data && resp.data.success);
      }
    });
  };

  const authUser = () => {
    dispatch(
      //@ts-ignore
      AuthActionCreators.login(dataPress.phone, dataPress.code)
    );
  };

  useEffect(() => {
    isTimer > 0 && setTimeout(() => setIsTimer(isTimer - 1), 1000);
  }, [isTimer]);

  useEffect(() => {
    if (isAuth) {
      navigate(RouteNames.HOMEPAGE);
    }
  }, [isAuth]);

  console.log("isLoading", isLoading);

  return (
    <div className="containerLogin">
      <h1>{title}</h1>

      {optionForm &&
        fieldToArray(optionForm).map((item) => {
          if (!isCode && item.key === "code") {
            return;
          }
          return (
            <div>
              <FormInput
                style={""}
                value={undefined}
                onChange={(e) => {
                  handleChange(item.key, e);
                }}
                subInput={item.value.label}
                required={item.value.required}
                error={""}
                keyData={""}
                type={item.value.type}
                friedlyInput
                disabled={item.key === "phone" && isCode && true}
                onClick={(e) => setIsCode(false)}
              />
              {item.key === "code" &&
                isCode &&
                (isTimer === 0 ? (
                  <p
                    className="buttonRepeat getCodeText"
                    onClick={() => {
                      getCode();
                    }}
                  >
                    Запросить повторно
                  </p>
                ) : (
                  <p className="buttonRepeat getCodeText">
                    {`Запросить повторно через  ${isTimer}`}
                  </p>
                ))}
            </div>
          );
        })}

      <Buttons
        text={isCode ? "Войти" : "Получить код"}
        className="buttonLogin"
        disabled={isLoading}
        ico={isLoading ? icons.ripples : ""}
        onClick={() => {
          isCode ? authUser() : getCode();
        }}
      />
    </div>
  );
};

export default LoginForm;
