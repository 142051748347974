import React, { FC, useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import ObjectInfoComponent from "../../components/ObjectInfoComponent/ObjectInfoComponent";
import ConstructionApiRequest from "../../api/Construction/Construction";
import { useParams } from "react-router-dom";
import { IConstructionInfo } from "../../models/IConstruction";

const ObjectInfoPage: FC = () => {
  const id = useParams();
  const constructionApi = new ConstructionApiRequest();

  const [dataConstruction, setDataConstruction] = useState<IConstructionInfo>();

  useEffect(() => {
    constructionApi.options().then((resp) => {
      if (resp.success) {
        console.log("resp", resp);
      }
    });
    constructionApi.list({ id: id.id }).then((resp) => {
      if (resp.success) {
        console.log("resp", resp);
        //@ts-ignore
        setDataConstruction(resp?.data);
      }
    });
  }, [id]);

  return (
    <section className="section">
      <Header />
      <ObjectInfoComponent
        //@ts-ignore
        dataConstruction={dataConstruction}
      />
    </section>
  );
};

export default ObjectInfoPage;
