export const API_CONSTRUCTION_MODEL = {
    entity: 'construction',
    url: 'construction/',
    methods: {
        stage:{
           url: 'stage/'
        },
        setStatus:{
           url: 'set_status/'
        },
        uploadFile:{
            url:'upload/'
        },
        updateMark:{
            url:'update_mark/'
        }
    },
}