export const API_TOKEN_MODEL = {
    entity: 'token',
    url: 'token/',
    methods: {

    },
}
export const API_USER_MODEL = {
    entity: 'user',
    url: 'user/',
    methods: {
        authCode:{
            url: 'auth-code'
        },
        authorize:{
            url: 'authorize'
        },
    },
}