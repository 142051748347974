import React, { FC, useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import CreateObjectComponent, {
  ICreateObject,
} from "../../components/CreateObjectComponent/CreateObjectComponent";
import ConstructionApiRequest from "../../api/Construction/Construction";
import { IOptionInput } from "../../models/IOptionInput";

const CreatePage: FC = () => {
  const constructionApi = new ConstructionApiRequest();
  const [dataOption, setDataOption] = useState<any>();

  useEffect(() => {
    constructionApi.options().then((resp) => {
      if (resp.success) {
        //@ts-ignore
        setDataOption(resp.data && resp?.data?.actions);
      }
    });
  }, []);

  return (
    <section className="section">
      <Header />
      <CreateObjectComponent dataOption={dataOption} />
    </section>
  );
};

export default CreatePage;
