import React, { useEffect, useState } from 'react';
import FormInput from '../FormInput/FormInput';
import Buttons from '../Buttons/Buttons';
import icons from '../../assets/icons/icons';
import './style.scss';

const FormBlock = ({ onClick, title, textAddButton, dataPlatform, onChange, dataOptions, initData = [] }) => {
    const [data, setData] = useState(() => {
        let arr = []
        for (let i = 0; i < initData?.length; i++) {
            arr.push(dataOptions)
        }
        return arr
    });
    const [dataBlock, setDataBlock] = useState(initData);

    const addFormBlock = () => {
        const newData = [
            ...data,
            dataOptions
        ];
        setData(newData);
    };

    const addPlatformBlock = () => {
        const newData = [
            ...data,
            dataOptions
        ];
        setData(newData);
    };

    const addDataBlock = (item = {}) => {
        const newBlockData = item;

        setDataBlock([...dataBlock, newBlockData]);
        //console.log('addDataBlock', [...dataBlock, newBlockData])
    };

    const handleBlockRemove = (index) => {
        const newData = [...data];
        const newDataBlock = [...dataBlock];
        newData.splice(index, 1);
        newDataBlock.splice(index, 1);
        setData(newData);
        setDataBlock(newDataBlock);
        onChange(newDataBlock);
    };



    const handleFieldChange = (blockIndex, fieldName, value) => {
        const updatedBlocksData = [...dataBlock];
        const updatedBlock = { ...updatedBlocksData[blockIndex] };

        updatedBlock[fieldName] = value;

        updatedBlocksData[blockIndex] = updatedBlock;

        setDataBlock(updatedBlocksData);
        onChange(updatedBlocksData);

    };

    useEffect(() => {
        dataPlatform && addPlatformBlock()
    }, [])

    console.log('data',data);

    return (
        <div>
            <div>
                {data?.map((block, blockIndex) => (
                    console.log('block', block),
                    <div key={block.key} className={'backGray'}>
                        <div className='headerFounder'>
                            <h1>{`${title} ${blockIndex + 1}`}</h1>
                            {/* {(dataPlatform && blockIndex === 0) ?
                                <></>
                                :
                                <img
                                    className='closeButton'
                                    src={icons.Close}
                                    alt='Remove'
                                    onClick={() => handleBlockRemove(blockIndex)}
                                />
                            } */}
                        </div>
                        <div>
                            {block?.map((item) => (
                                item && item.key !== 'id' &&  item.key !== 'mark_document'  &&  item.key !== 'mark_photos' &&  item.key !== 'constraction_install'&& ( // Add a check for item
                                    <FormInput
                                        subInput={item?.value?.label}
                                        required={item?.value?.required}
                                        options={item?.value?.choices}
                                        type={item?.value?.type}
                                        style='mt-16'
                                        value={dataBlock?.length > 0 && dataBlock[blockIndex] ? dataBlock[blockIndex][item.key] : ''}
                                        onChange={(e) => {
                                            handleFieldChange(blockIndex, item.key, e);
                                        }}
                                    />
                                )
                            ))}
                        </div>

                    </div>
                ))}
            </div>
            <Buttons
                ico={icons.calendarPlus}
                text={textAddButton}
                className={'addButton'}
                onClick={dataPlatform ? addPlatformBlock : () => {
                    addFormBlock();
                    addDataBlock();
                }}
            />

        </div>
    );
};

export default FormBlock;
