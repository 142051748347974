import React, {
  FC,
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./styles.scss";
import {
  fieldToArray,
  formatDateIntlDate,
  formatDateIntlDateTime,
} from "../UI/functions/functions";
import Buttons from "../Buttons/Buttons";
import icons from "../../assets/icons/icons";
import { useNavigate, useParams } from "react-router-dom";
import { RouteNames } from "../../routes";
import FormInput from "../FormInput/FormInput";
import Modal from "../Modal/Modal";
import UserApiRequest from "../../api/User/Users";
import ConstructionApiRequest from "../../api/Construction/Construction";
import apiConfig from "../../api/apiConfig";
import { DataPressActionCreators } from "../../store/reducers/dataPressItem/action-creator";
import { useDispatch } from "react-redux";
import { useTypeSelector } from "../../hooks/useTypedSelector";
import ErrorMessage from "../UI/ErrorMassage/ErrorMassage";
import FormReport from "../FormReport/FormReport";
import { IOptionInput } from "../../models/IOptionInput";

interface IList {
  address: IOptionInput;
  contractor: IOptionInput;
  created_at: IOptionInput;
  id: IOptionInput;
  name: IOptionInput;
  responsible: IOptionInput;
  stages: IOptionInput;
}

interface IData {
  CREATE: IList;
}

export interface ICreateReport {
  dataOption: IData;
  dataConstruction: any;
}

const ObjectReport: FC<ICreateReport> = ({ dataConstruction, dataOption }) => {
  const navigate = useNavigate();
  const userApi = new UserApiRequest();
  const constructionApi = new ConstructionApiRequest();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isError, setIsError] = useState({ text: "", type: "error" });
  const [isCode, setIsCode] = useState("");
  const [isComment, setIsComments] = useState("");
  const [isCodeText, setIsCodeText] = useState("");
  const [isTimer, setIsTimer] = useState<number>(0);
  const urlParams = new URLSearchParams(window.location.search);
  const constructionPk = urlParams.get("construction_pk") || "";
  const id = useParams();
  const dispatch = useDispatch();

  const userData = JSON.parse(localStorage.getItem("account") || "{}");

  const dataPress = useTypeSelector(
    (state: any) => state.dataPressReducer.dataPress
  );

  const userResponsible = userData.id === dataPress.responsible;

  useEffect(() => {
    dispatch(DataPressActionCreators.clearDataPress());
    constructionApi
      .constructionStageList(constructionPk, id.id)
      .then((resp) => {
        if (resp.success) {
          setIsSaved(true);
          resp.data &&
            fieldToArray(resp.data).map((item) =>
              dispatch(
                DataPressActionCreators.setDataPress(item.key, item.value)
              )
            );
        }
      });
  }, []);

  useEffect(() => {
    isTimer > 0 && setTimeout(() => setIsTimer(isTimer - 1), 1000);
  }, [isTimer]);

  const handleOpenModal = () => {
    const phoneAccount = JSON.parse(
      localStorage.getItem("account") || "{}"
    ).phone;
    userResponsible
      ? userApi
          .authCode({
            phone: phoneAccount,
          })
          .then((resp) => {
            if (resp.success) {
              setIsCode(resp.data && resp.data.code);
              setIsOpenModal(true);
              setIsTimer(60);
            }
          })
      : setIsOpenModal(true);
  };

  const handleUploadFiles = (dataImage: FileList | null, type: string) => {
    const formData = new FormData();

    if (dataImage) {
      Array.from(dataImage).forEach((file: any) => {
        formData.append("file", file);
      });

      constructionApi.constructionUpload(formData).then((resp) => {
        if (resp.success) {
          if (type === "image") {
            //@ts-ignore
            handleChange("images", resp.data);
          } else {
            //@ts-ignore
            handleChange("documents", resp.data);
          }
        }
      });
    }
  };

  const handleChange = (fieldName: string, fieldValue: string | boolean) => {
    setIsSaved(false);
    dispatch(DataPressActionCreators.setDataPress(fieldName, fieldValue));
  };

  const handleMarks = (fieldName: string, fieldValue: string | boolean) => {
    dispatch(DataPressActionCreators.setDataPress(fieldName, fieldValue));
    const data = {
      [fieldName]: fieldValue,
    };
    constructionApi.updateMark(constructionPk, id.id, data).then((resp) => {
      if (resp.success) {
      }
    });
  };

  const saveReport = () => {
    constructionApi
      .constructionStagePatch(constructionPk, id.id, dataPress)

      .then((resp) => {
        if (resp.success) {
          setIsError({ text: "Данные обновлены", type: "success" });
          setIsSaved(true);
        }
      });
  };

  console.log("isComm", isComment);

  const stageReady = () => {
    constructionApi
      .constructionStagePatch(constructionPk, id.id, dataPress)

      .then((resp) => {
        if (resp.success) {
          constructionApi
            .constructionStageReady(constructionPk, id.id, { code: isCodeText })
            .then((resp) => {
              if (resp.success) {
                navigate(-1);
              }
            });
        }
      });
  };
  const stageSetStatus = (status: boolean) => {
    constructionApi
      .constructionStageStatus(constructionPk, id.id, {
        comment: isComment,
        accept: status,
      })
      .then((resp) => {
        if (resp.success) {
          navigate(-1);
        }
      });
  };

  const goBack = () => {
    if (isSaved) {
      navigate(-1);
    } else {
      setIsError({ text: "Вы не соханили данные", type: "warn" });
    }
  };

  console.log("dada", dataPress);

  return (
    <Fragment>
      {isError.text != "" && (
        <ErrorMessage
          type={isError.type}
          message={isError.text}
          onClose={() => {
            setIsError({ text: "", type: "error" });
          }}
        />
      )}
      <Modal
        classModal="modalSms"
        content={
          <div className="modalSmsContainer">
            <div>
              <h1>
                {userResponsible ? "Подтвердить отправку" : "Отклонить отчет"}
              </h1>
              <p className="subtitleModal">
                {userResponsible
                  ? "Вы больше не сможете отредактировать отчет, а факт отправки будет подтвержден СМС-подписью"
                  : "Вы собираетесь отклонить предоставленный отчет и запросить данные повторно"}
              </p>
            </div>
            <div>
              <FormInput
                style={""}
                value={undefined}
                onChange={(e) =>
                  userResponsible ? setIsCodeText(e) : setIsComments(e)
                }
                subInput={userResponsible ? "Код из СМС" : "Причина"}
                required={true}
                error={""}
                keyData={""}
                friedlyInput={true}
                textArea={!userResponsible}
              />
              {userResponsible &&
                (isTimer === 0 ? (
                  <p
                    className="buttonRepeat getCodeText"
                    onClick={() => handleOpenModal()}
                  >
                    Запросить повторно
                  </p>
                ) : (
                  <p className="buttonRepeat getCodeText">
                    {`Запросить повторно через  ${isTimer}`}
                  </p>
                ))}
            </div>
            <div className="modalContainerButton">
              <Buttons
                text={"Отмена"}
                className="closeModal"
                onClick={() => setIsOpenModal(false)}
              />
              <Buttons
                text={userResponsible ? "Отправить" : "Отклонить"}
                className={userResponsible ? "" : "completeModal"}
                onClick={() =>
                  userResponsible ? stageReady() : stageSetStatus(false)
                }
              />
            </div>
          </div>
        }
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
      />
      <div className="reportCotainer">
        <Buttons
          ico={icons.Arrow}
          text={"К списку этапов"}
          onClick={() => goBack()}
          className="backButton"
        />
        <p className="dateDeadline">{`Выполнить до ${
          dataPress.deadline ? formatDateIntlDate(dataPress.deadline) : ""
        }`}</p>
        <h1 className="constructionInfoTitle">Подготовительные работы</h1>
        {/* <h5 className="constructionInfoAddress">{dataConstruction?.address}</h5> */}
        {dataPress?.action_history?.length > 0 &&
          dataPress.action_history.map((item: any) => {
            return (
              <FormReport
                userResponsible={userResponsible}
                handleUploadFiles={(files, text) =>
                  handleUploadFiles(files, text)
                }
                handleChange={(key: string, text: string) =>
                  handleChange(key, text)
                }
                handleOpenModal={() => handleOpenModal()}
                handleStageStatus={() => stageSetStatus(false)}
                dataPress={item}
                disabled={true}
              />
            );
          })}

        <FormReport
          userResponsible={userResponsible}
          handleUploadFiles={(files, text) => handleUploadFiles(files, text)}
          handleChange={(key: string, text: string) => handleChange(key, text)}
          handleOpenModal={() => handleOpenModal()}
          dataPress={dataPress}
          disabled={dataPress.status === 2}
          handleStageStatus={() => stageSetStatus(true)}
          dataOption={dataOption}
          handleMarks={(key: string, e: string | boolean) =>
            handleMarks(key, e)
          }
        />
        {dataPress.status === 2 && (
          <h1 className="successReport">
            <img src={icons.CheckCircle}></img>
            Отчёт принят
          </h1>
        )}
        {/* {userResponsible && dataPress.status !== 2 && (
          <Buttons
            text={"Сохранить"}
            onClick={() => saveReport()}
            className="saveButtonFixed"
          />
        )} */}
      </div>
    </Fragment>
  );
};

export default ObjectReport;
