import React, { FC, Fragment, useEffect, useState } from "react";
import "./styles.scss";
import Header from "../../components/Header/Header";
import ConstructionApiRequest from "../../api/Construction/Construction";
import CardControl from "../../components/CardControl/CardControl";
import { ICreateObject } from "../../models/IControl";
import { IPaginationData } from "../../models/IPagination";

interface UsefullItem {
  id: number;
  link: string;
  name: string;
}

interface UsefullProps {
  results: UsefullItem[];
  count: number;
  next: string | null;
  previous: string | null;
}

const Reestr: FC = () => {
  const constructionApi = new ConstructionApiRequest();
  const [dataConstruction, setDataConsruction] = useState<IPaginationData>();

  useEffect(() => {
    constructionApi.list().then((resp) => {
      console.log("resp", resp);
      if (resp.success) {
        setDataConsruction(resp.data as IPaginationData);
      }
    });
  }, []);

  return (
    <section className="section">
      <Header handleDataConstruction={(e) => setDataConsruction(e)} />
      <div className="containerControl">
        {dataConstruction?.results.map((item) => {
          return (
            //@ts-ignore
            <CardControl data={item} />
          );
        })}
      </div>
    </section>
  );
};

export default Reestr;
