import React from "react";
import Login from "../pages/Login/Login";
import HomePage from "../pages/HomePage/HomePage";
import CreatePage from "../pages/CreatePage/CreatePage";
import ObjectInfoPage from "../pages/ObjectInfoPage/ObjectInfoPage";
import CreateReport from "../pages/CreateReport/CreateReport";

const isAuthenticated = localStorage.getItem("account");
const isAuthenticatedApplicant = localStorage.getItem("applicant");
const userData = JSON.parse(isAuthenticated || "{}");


export interface IRoute {
    path: string;
    element : React.ComponentType;
    exact?: boolean;
    params?: { [key: string]: string | number };
}

export enum RouteNames {
    LOGIN = '/login',
    HOMEPAGE = '/',
    CREATEOBJECT = '/create-object',
    CREATEREPORT = '/create-report',
    OBJECTINFO = '/object-info',
}

export const navDate = [
  {
    id: 1,
    name: "Авторизация",
    link: RouteNames.LOGIN,
  },
];

export const publicRoutes: IRoute[] = [
  {path: RouteNames.LOGIN, exact: true, element: Login},
]

export const privateRoutes: IRoute[] = [
  { path: RouteNames.LOGIN, exact: false, element: Login },
  { path: RouteNames.HOMEPAGE, exact: true, element: HomePage },
  { path: `${RouteNames.CREATEREPORT}/:id`, exact: false, element: CreateReport, params: { id: ':id' } },
  { path: `${RouteNames.OBJECTINFO}/:id`, exact: false, element: ObjectInfoPage, params: { id: ':id' } },
   { path: RouteNames.CREATEOBJECT, exact: false, element: CreatePage },
  ...(userData?.roles ? userData.roles.flatMap((item: any) => {
    
    if (item === "EMPLOYEE" || item === 'ADMIN') {
      return [
       
      ];
    }
    return [];
  }) : [])
];
