import React, { FC } from "react";
import "./styles.scss";
import {
  formatDateIntlDate,
  formatDateIntlDateTime,
} from "../UI/functions/functions";
import Buttons from "../Buttons/Buttons";
import icons from "../../assets/icons/icons";
import { useNavigate, useParams } from "react-router-dom";
import { RouteNames } from "../../routes";
import ErrorMessage from "../UI/ErrorMassage/ErrorMassage";

const ObjectInfoComponent: FC = ({ dataConstruction }: any) => {
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("account") || "{}");

  const statusCompleteCount =
    dataConstruction?.stages &&
    //@ts-ignore
    dataConstruction?.stages?.filter((item: any) => item.status === 2).length;

  return (
    <div className="constructionInfoCotainer">
      <Buttons
        ico={icons.Arrow}
        text={"К списку объектов"}
        onClick={() => navigate(-1)}
        className="backButton"
      />
      <h1 className="constructionInfoTitle">{dataConstruction?.name}</h1>
      <h5 className="constructionInfoAddress">{dataConstruction?.address}</h5>

      <div className="constructionInfoContainerPeople">
        <div>
          <p className="titleInfoPeople">Ответственный</p>
          <b className="nameInfoPeople">{dataConstruction?.responsible?.fio}</b>
          <h5 className="phoneInfoPeople">
            {`+7${dataConstruction?.responsible?.phone}`}
          </h5>
        </div>
        <div>
          <p className="titleInfoPeople">Подрядчик</p>
          <b className="nameInfoPeople">{dataConstruction?.contractor}</b>
        </div>
      </div>

      <div className="containerStage">
        <p className="numberConstruction">
          Выполнено:
          <span>{` ${statusCompleteCount} из ${dataConstruction?.stages?.length} этапов`}</span>
        </p>
        {dataConstruction?.stages.map((item: any) => {
          return (
            <div
              key={item.id}
              className="stageContainer"
              onClick={() => {
                navigate(
                  `${RouteNames.CREATEREPORT}/${item.id}?construction_pk=${dataConstruction.id}`
                );
              }}
            >
              <div>
                <h1>{item?.name}</h1>
                <div className="containerStatus">
                  {(() => {
                    switch (item.status) {
                      case 1:
                        return <></>;
                      case 2:
                        return <p className="successStatus">Принят</p>;
                      case 3:
                        return <p className="errorStatus">Отклонен</p>;
                      default:
                        return null;
                    }
                  })()}
                  <p>{`Выполнить до ${item?.deadline}`}</p>
                </div>

                <div className="containerInfoMark">
                  <div className="infoMark">
                    <h5>СМР</h5>
                    <p
                      className={`${(() => {
                        switch (item.constraction_install) {
                          case "Не предоставлены":
                            return "red";
                          case "Завершено":
                            return "green";
                          default:
                            return "gray";
                        }
                      })()}`}
                    >
                      {item.constraction_install}
                    </p>
                  </div>
                  <div className="infoMark">
                    <h5>Документы</h5>
                    <img
                      src={
                        item.mark_document ? icons.checkGreen : icons.xCloseTwo
                      }
                    ></img>
                  </div>
                  <div className="infoMark">
                    <h5>Фото</h5>
                    <img
                      src={
                        item.mark_photos ? icons.checkGreen : icons.xCloseTwo
                      }
                    ></img>
                  </div>
                </div>
              </div>
              {item.is_ready ? (
                userData.id === dataConstruction.responsible.id ? (
                  <Buttons
                    text={"Отчет отправлен"}
                    className="whiteButton"
                    onClick={function (): void {
                      throw new Error("Function not implemented.");
                    }}
                  />
                ) : (
                  <Buttons
                    text={"Проверить"}
                    className="blueButton"
                    onClick={function (): void {
                      throw new Error("Function not implemented.");
                    }}
                  />
                )
              ) : item.is_overdue ? (
                <p className="isOverdue">Отчет не сдан</p>
              ) : (
                (() => {
                  switch (item.status) {
                    case 1:
                      return userData.id === dataConstruction.responsible.id ? (
                        <Buttons
                          text={"Сдать отчет"}
                          className="blueButton"
                          onClick={function (): void {
                            throw new Error("Function not implemented.");
                          }}
                        />
                      ) : (
                        // <Buttons
                        //   text={"Проверить"}
                        //   onClick={function (): void {
                        //     throw new Error("Function not implemented.");
                        //   }}
                        // />
                        <></>
                      );
                    case 2:
                      return <p className="isComplete">Отчет принят</p>;
                    case 3:
                      return <p className="isOverdue">Отчет не сдан</p>;
                    default:
                      return null;
                  }
                })()
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ObjectInfoComponent;
