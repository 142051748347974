import React, { FC } from "react";
import "./styles.scss";
import icons from "../../assets/icons/icons";

const HeaderLogin: FC = () => {
  return (
    <div className="headerLoginContainer">
      <div className="containerLogoLogin">
        <img src={icons.bikLogo} className="bikLogoLogin"></img>
        <h1>Контроль строительства</h1>
      </div>
    </div>
  );
};

export default HeaderLogin;
