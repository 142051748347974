import React, { FC } from "react";
import { IOptionInput } from "../../models/IOptionInput";
import { IData, IDataControl } from "../../models/IControl";
import "./styles.scss";
import {
  formatDateIntlDate,
  formatPhoneNumber,
} from "../UI/functions/functions";
import { RouteNames } from "../../routes";
import { useNavigate } from "react-router-dom";

const CardControl: FC<IDataControl> = ({ data }) => {
  const navigation = useNavigate();

  const statusCompleteCount =
    data?.stages &&
    //@ts-ignore
    data?.stages?.filter((item: any) => item.status === 2).length;

  return (
    <div
      className={`cardControl ${data.notify && "notify"}`}
      onClick={() => navigation(`${RouteNames.OBJECTINFO}/${data.id}`)}
    >
      <div className="cardControlFlex">
        <div className="cardContainerName">
          <h1 className="cardName">{data.name}</h1>
          <h4 className="cardAddress">{data.address}</h4>
        </div>

        <div className="userContainer">
          <p className="titleInfo">Ответственный </p>
          <p className="fioResponsible">{data?.responsible?.fio}</p>
          <a
            className="telResponsible"
            href={`tel:${data?.responsible?.phone}`}
          >
            {`+7${data?.responsible?.phone}`}
          </a>
        </div>
      </div>
      <div className="cardControlFlexEnd">
        <div className="cardContainerStages">
          <h4 className="cardStages">{`${statusCompleteCount} из ${data?.stages?.length} этапов`}</h4>
          <h4 className="cardDate">
            {`Срок сдачи объекта: 
            ${data?.deadline}`}
          </h4>
        </div>
      </div>
      <div className="cardControlFlexEnd">
        <div>
          <h5 className="stagesTitle">Текущие этапы</h5>
          {
            //@ts-ignore
            data?.nearest_stages?.map((item: any) => {
              return (
                <p className="stagesCardText">
                  {`${item.name} `}
                  <b>до {formatDateIntlDate(item.deadline)}</b>
                </p>
              );
            })
          }
        </div>
        <div>
          <p className="titleInfo">Подрядчик </p>
          <p className="contractorTitle">{data.contractor}</p>
        </div>
      </div>
    </div>
  );
};

export default CardControl;
