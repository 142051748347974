import React, { FC, Fragment, useEffect, useState } from "react";
import "./styles.scss";
import { RouteNames } from "../../routes";
import { useNavigate } from "react-router-dom";
import { ISendModeration } from "../../models/ISendModeration";
import { ISendLogin } from "../../models/ISendLogin";
import { useTypeSelector } from "../../hooks/useTypedSelector";
import { useDispatch } from "react-redux";
import icons from "../../assets/icons/icons";
import HeaderLogin from "../../components/HeaderLogin/HeaderLogin";
import LoginForm from "../../components/LoginForm/LoginForm";
import UserApiRequest from "../../api/User/Users";

const Login: FC = () => {
  const navigation = useNavigate();

  const userApi = new UserApiRequest();

  const [isSendToModeration, setSendToModeration] = useState<ISendModeration>();
  const [loginData, setLoginData] = useState<ISendLogin>();
  const [loginOption, setLoginOption] = useState<ISendLogin>();

  const dispatch = useDispatch();

  const { isAuth, error, isLoading, data } = useTypeSelector(
    (state) => state.authReducer
  );

  useEffect(() => {
    userApi.options().then((resp) => {
      if (resp.success) {
        //@ts-ignore
        setLoginOption(resp?.data?.actions?.LOGIN as ISendLogin);
      }
    });
  }, []);

  return (
    <Fragment>
      <img src={icons.bikCover} className="bgLogin"></img>
      <section className="section">
        <HeaderLogin />

        <div className="containerLoginPage">
          <LoginForm title={"Вход в систему"} optionForm={loginOption} />

          <h4>Обращения по работе системы: 8 800 301 29 31 (доб. 1533)</h4>
        </div>
      </section>
    </Fragment>
  );
};

export default Login;
